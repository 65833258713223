import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.888 27.166c0 .137-.158.246-.356.246-.225.02-.383-.089-.383-.246 0-.137.158-.246.356-.246.205-.02.383.089.383.246Zm-2.126-.308c-.048.137.089.294.294.335.177.069.383 0 .424-.136.04-.137-.09-.294-.295-.356-.177-.048-.375.02-.423.157Zm3.021-.116c-.198.048-.335.178-.314.335.02.137.198.226.403.178.198-.048.335-.178.315-.315-.021-.13-.206-.218-.404-.198ZM17.281.547C7.8.547.547 7.745.547 17.227c0 7.58 4.771 14.068 11.587 16.351.875.157 1.182-.383 1.182-.827 0-.424-.02-2.762-.02-4.197 0 0-4.785 1.025-5.79-2.037 0 0-.78-1.99-1.9-2.502 0 0-1.566-1.074.109-1.053 0 0 1.702.137 2.639 1.764 1.497 2.638 4.005 1.88 4.983 1.428.157-1.093.601-1.852 1.094-2.303-3.822-.424-7.677-.978-7.677-7.554 0-1.88.52-2.823 1.613-4.027-.178-.444-.759-2.276.178-4.641 1.429-.444 4.717 1.846 4.717 1.846a16.061 16.061 0 0 1 4.293-.581c1.456 0 2.925.198 4.293.58 0 0 3.288-2.296 4.716-1.845.937 2.372.356 4.197.178 4.642 1.094 1.21 1.764 2.153 1.764 4.026 0 6.597-4.026 7.123-7.848 7.554.63.54 1.162 1.565 1.162 3.172 0 2.303-.02 5.154-.02 5.714 0 .445.314.985 1.182.828 6.836-2.27 11.471-8.757 11.471-16.338 0-9.482-7.69-16.68-17.172-16.68ZM7.191 24.124c-.088.068-.068.226.048.356.11.109.267.157.356.068.089-.068.068-.226-.048-.356-.11-.109-.267-.157-.356-.068Zm-.738-.554c-.048.09.02.199.157.267.11.068.246.048.294-.048.048-.089-.02-.198-.157-.267-.137-.04-.246-.02-.294.048Zm2.215 2.434c-.11.089-.068.294.089.424.157.157.355.177.444.068.089-.089.048-.294-.089-.424-.15-.157-.355-.177-.444-.068Zm-.78-1.005c-.109.068-.109.246 0 .403.11.158.295.226.383.158.11-.09.11-.267 0-.424-.095-.157-.273-.226-.382-.137Z"
    />
  </svg>
)
export default SvgComponent
