import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M30.625 2.188H4.368c-1.203 0-2.18.99-2.18 2.208v26.209c0 1.216.977 2.207 2.18 2.207h26.257c1.203 0 2.188-.99 2.188-2.207V4.395c0-1.216-.985-2.207-2.188-2.207Zm-19.182 26.25H6.904V13.822h4.546v14.616h-.007Zm-2.27-16.612a2.633 2.633 0 0 1 0-5.264 2.637 2.637 0 0 1 2.633 2.632 2.629 2.629 0 0 1-2.632 2.632Zm19.285 16.611h-4.54v-7.109c0-1.695-.033-3.876-2.357-3.876-2.366 0-2.728 1.846-2.728 3.753v7.233h-4.54V13.822h4.355v1.996h.062c.608-1.148 2.092-2.358 4.3-2.358 4.593 0 5.448 3.028 5.448 6.966v8.012Z"
    />
  </svg>
)
export default SvgComponent
