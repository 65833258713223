import React from "react";
import StudentFellesskapet from "../images/StudentFellesskapet.png";
import Havborsen from "../images/www.havborsen.no_job.png";
import MiSide from "../images/EnivestMiSide.png";

const About = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="text-[#577B8D] mb-2">
        <h2 className="text-2xl font-semibold mb-1 text-[#344C64]">Hi! My name is Sondre Langedal Ness. 👋🏼</h2>
        <p className="text-base">
          I am a software engineer with a passion for web development, design, and technology.
          I am currently pursuing a Bachelor's degree in Computer Science at the Western University of Applied Sciences (HVL) in Førde.
        </p>
      </div>

      <div className="mb-0">
        <h2 className="text-lg font-semibold text-[#344C64] mb-2">What have I been up to lately?</h2>

        <div className="mb-0">
          <h3 className="text-lg font-semibold text-[#577B8D] mb-2">Mobile Applications</h3>
          <div className="flex flex-wrap justify-between">
            <div className="text-center w-full md:w-[48%] mb-6 md:mb-0">
              <h4 className="text-lg font-bold mb-2">StudentFellesskapet</h4>
              <img src={StudentFellesskapet} alt="studentfellesskapet app" className="h-[300px] w-full object-contain rounded-lg shadow-md" />
              <p className="my-2 text-sm text-[#577B8D]">
                StudentFellesskapet is an mobile application to help students find studentorganizations at they're campus, 
                and to improve the way these organizations communicate with their members.
                <br />
                Built in Flutter.
              </p>
            </div>
            <div className="text-center w-full md:w-[48%] mb-6 md:mb-0">
              <h4 className="text-lg font-bold mb-2">Enivest Mi Side</h4>
              <img src={MiSide} alt="Enivest mi side" className="h-[300px] w-full object-contain rounded-lg shadow-md" />
              <p className="my-2 text-sm text-[#577B8D]">
                Enivest Mi Side is a mobile application for Enivest AS customers to manage their services, invoices, and contact support.
                The customers are also able to manage they're internet, boost they're wifi, and view they're invoices, etc. 
                <br />
                Built in React Native.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-[#577B8D] mb-2">Websites</h3>
          <div className="text-center">
            <h4 className="text-lg font-bold mb-2">Havbørsen</h4>
            <img src={Havborsen} alt="Havbørsen website" className="h-[300px] w-full object-contain rounded-lg shadow-md" />
            <p className="my-2 text-sm text-[#577B8D]">
              Havbørsen is a website to help companies in the marine industry to add job offers and sell equipment, with a focus on the maritime industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
