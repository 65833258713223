import React, { useState } from 'react';
import Navigation from './components/Navigation';
import Praksis from './components/Praksis';
import Blog from './components/Blog';
import About from './components/About';

function App() {
  const [ page, setPage ] = useState('home');

  const servePage = () => {
    switch (page) {
      case 'home':
        return <Praksis />;
      case 'blog':
        return <Blog />;
      case 'about':
        return <About />;
      default:
        return <h1>404</h1>;
    }
  }

  return (
    <div className='flex flex-row h-full'>
      <div className="w-[300px]">
        <Navigation setPage={setPage} />
      </div>
      <div className='flex-grow'>
        {servePage()}
      </div>
    </div>
  );  
}

export default App;
