import React from "react";
import { Tooltip } from 'react-tooltip';

const Praksis = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-4xl font-bold mb-4 text-[#344C64]">Praksis i arbeidslivet</h1>
      <p className="text-base text-[#577B8D] mb-2">
        I det femte semesteret på Bachelor i Informatikk ved Høgskulen på Vestlandet (HVL) i Førde, har jeg praksis i Enivest AS.
      </p>
      <p className="text-base text-[#577B8D] mb-2">
        Enivest AS er en lokal breibandstilbydar som leverer internett, TV og telefoni til privat- og bedriftsmarknaden i Sogn og Fjordane.
      </p>
      <p className="text-base text-[#577B8D] mb-2">
        Utviklingsteamet hos Enivest består av dyktige utviklere som jobbar med alt frå kundesystem, nettverk, infrastruktur og automatisering.
        Teamet består av 8 utviklere, scrum master, produktansvarleg og avdeligsleiar. Vi jobbar i sprintar med varigheit på 2 veke og har daglege standup-møte.
        Etter kvar sprint har vi sprint review og sprint retrospective for å evaluere sprinten og klargjøre neste sprint.
        <br />
        Vi jobbar med fleire teknologiar som{" "}
        <span data-tooltip-id="react-tooltip" data-tooltip-content="Et JavaScript-bibliotek for å bygge brukergrensesnitt">
          React, {" "}
        </span>
        <span data-tooltip-id="php-tooltip" data-tooltip-content="Et populært server-side skriptspråk for webutvikling">
          PHP, {" "}
        </span>
        <span data-tooltip-id="python-tooltip" data-tooltip-content="Et allsidig språk brukt til webutvikling, dataanalyse og mer">
          Python, {" "}
        </span>
        <span data-tooltip-id="sql-tooltip" data-tooltip-content="Et domene-spesifikt språk designet for å håndtere data i et relasjonsdatabase-system">
          SQL, {" "}
        </span>
        <span data-tooltip-id="docker-tooltip" data-tooltip-content="En plattform for utvikling, shipping og kjøring av applikasjoner i containere">
          Docker, {" "}
        </span>
        <span data-tooltip-id="azure-tooltip" data-tooltip-content="Microsofts skyplattform og tjenester">
          Azure, {" "}
        </span>
        <span data-tooltip-id="git-tooltip" data-tooltip-content="Et distribuert versjonskontrollsystem for å spore endringer i kildekode under utvikling">
          Git
        </span>
        {" "} og mykje meir.
        <Tooltip id="react-tooltip" />
        <Tooltip id="php-tooltip" />
        <Tooltip id="python-tooltip" />
        <Tooltip id="sql-tooltip" />
        <Tooltip id="docker-tooltip" />
        <Tooltip id="azure-tooltip" />
        <Tooltip id="git-tooltip" />
      </p>

      <a
        href="https://www.enivest.no/"
        target="_blank"
        rel="noreferrer"
        className="text-sm text-[#240750] hover:underline"
      >
        Les meir om Enivest AS!
      </a>
    </div>
  );
};

export default Praksis;
