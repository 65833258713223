import React from "react";
import { GithubIcon, LinkedInIcon } from "../icons";
import Avatar from "../images/IMG_6054.jpg";
import SosialLinks from "./SosialLinks";

type NavigationProps = {
  setPage: (page: string) => void;
};

const Navigation = ({ setPage }: NavigationProps) => {
  return (
    <div className="bg-[#240750] min-w-[300px] h-full flex flex-col text-white sticky top-0">
      <img
        src={Avatar}
        alt="Avatar"
        className="rounded-full h-40 w-40 object-cover mx-auto my-10"
      />

      <div className="mx-auto">
        <SosialLinks Icon={LinkedInIcon} text="Sondre Langedal Ness" href="https://www.linkedin.com/in/sondre-langedal-ness-66b8b9135/" />
        <div className="mb-2"/>
        <SosialLinks Icon={GithubIcon} text="@sondreln" href="https://www.github.com/sondreln" />

        <nav className="my-10">
          <ul className="flex flex-col gap-2 font-semibold">
            <li className="cursor-pointer hover:underline" onClick={() => setPage('home')}>Praksis i arbeidslivet</li>
            <li className="cursor-pointer hover:underline" onClick={() => setPage('blog')}>Blog</li>
            <li className="cursor-pointer hover:underline" onClick={() => setPage('about')}>Sondre</li>
          </ul>
        </nav>

        <button className="flex mx-auto cursor-pointer">
          <a
            href="mailto:sondrelangedal.ness@gmail.com"
            className="bg-[#57A6A1] text-[#240750] px-4 py-2 rounded-md font-semibold hover:scale-105 transition-transform"
          >
            Kontakt meg
          </a>
        </button>
      </div>
    </div>
  );
};

export default Navigation;
