import React, { useState, Suspense } from "react";
import Select from "react-select";

interface SelectOption {
  value: string;
  label: string;
}

const Blog: React.FC = () => {
  const options: SelectOption[] = [
    { value: "Day1", label: "Dag 1" },
    { value: "Day2", label: "Dag 2" },
    { value: "Day3", label: "Dag 3" },
    { value: "Day4", label: "Dag 4" },
    { value: "Day5", label: "Dag 5" },
    { value: "Day6", label: "Dag 6" },
    { value: "Day7", label: "Dag 7" },
    { value: "Day8", label: "Dag 8" },
    { value: "Day9", label: "Dag 9" },
    { value: "Day10", label: "Dag 10" },
    { value: "Day11", label: "Dag 11" },
    { value: "Day12", label: "Dag 12" },
    { value: "Day13", label: "Dag 13" },
    { value: "Day14", label: "Dag 14" },
  ];

  const latestPost = options[options.length - 1]?.value || "";
  const [selectedDay, setSelectedDay] = useState<string>(latestPost);
  const DayComponent = React.lazy(() => import(`./Days/${selectedDay}`));

  return (
    <div className="">
      <div className="p-4 bg-[#577B8D] text-white">
        <p className="text-sm text-[#240750] font-semibold">Antall timer i praksis: {(options.length * 8) + 1}</p>
        <p className="text-lg font-bold">Praksis i Enivest AS</p>
        <p className="text-sm italic font-normal mb-4">
          Her kan du bla deg gjennom dag, for dag, og lese om mine erfaringer fra praksisperioden i Enivest AS. 🤖
        </p>
        <Select
          options={options}
          placeholder="Velg dag"
          defaultValue={options.find((option) => option.value === latestPost)}
          onChange={(selectedOption) => setSelectedDay(selectedOption?.value || "")}
          styles={{
            option: (styles, { isFocused }) => ({
              ...styles,
              backgroundColor: isFocused ? "#577B8D" : "white",
              color: isFocused ? "white" : "#577B8D",
              cursor: "pointer",
            }),
            container: (styles) => ({
              ...styles,
              width: "200px",
            }),
          }}
        />
      </div>

      {/* CONTENT */}
      <div className="p-6">
        <Suspense fallback={<div>Loading...</div>}>
          <DayComponent />
        </Suspense>
      </div>
    </div>
  );
};

export default Blog;
