import React from "react";

type SosialLinksProps = {
  Icon: React.FC;
  text: string;
  href?: string;
};

const SosialLinks = ({Icon, text, href} : SosialLinksProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="flex-row flex gap-2 items-center text-center h-10 font-semibold">
      <Icon />
      <p>{text}</p>
    </a>
  );
}

export default SosialLinks;